import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import { SeoService } from 'src/app/common/services/seo.service/seo.service';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { PlaceDetailsBaseComponent } from '../place.details.base';
import { IPlaceDetailsModalData } from '../place.details.modal.provider';
import { GoogleMapComponent } from 'src/app/modules/shared/components/google.map/google.map.component';
import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  FancyEventDetailsModalComponent
} from 'src/app/modules/event-calendar/components/event.details.modal/fancy.event.details.modal/fancy.event.details.modal.component';
import {
  IEventDetailsModalData
} from 'src/app/modules/event-calendar/components/event.details.modal/event.details.modal.provider';
import { TimelineComponent } from 'src/app/modules/event-calendar/components/timeline/timeline.component';
import { NearbyPlacesComponent } from 'src/app/modules/shared/components/nearby.places/nearby.places.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, filter, of, switchMap } from 'rxjs';
import {
  EventDateFilterComponent
} from 'src/app/modules/event-calendar/components/event.date.filter/event.date.filter.component';
import { isEmptyArray } from 'src/app/common/utils/object.extensions';
import { IMapMarkerWithCustomData } from 'src/app/modules/shared/models/place.marker';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';

@Component({
  selector: 'ig-fancy-place-details-modal',
  templateUrl: './fancy.place.details.modal.component.html',
  styleUrls: ['./fancy.place.details.modal.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class FancyPlaceDetailsModalComponent extends PlaceDetailsBaseComponent {
  // settings: IFrontendSettings;
  @ViewChild('map', {static: false}) googleMap: GoogleMapComponent;

  @ViewChild('nearbyEventsContainer', {static: false})
  nearbyEventsContainer: ElementRef;
  @ViewChild('card', {static: false}) card: ElementRef;
  @ViewChild('timeline', {static: false}) timeline: TimelineComponent;
  @ViewChild('dateFilter', {static: false})
  dateFilter: EventDateFilterComponent;

  @ViewChild(MatMenuTrigger, {static: false})
  matMenuTrigger: MatMenuTrigger;

  @ViewChild('nearbyPlacesCom', {static: false})
  nearbyPlacesCom: NearbyPlacesComponent;

  isAtStart = true;
  isAtEnd = false;

  showMessager = false;
  nearbyEventsRange = 2;

  nearbyEventsMode: 'normal' | 'timeline' = 'normal';
  range: number = 2;

  isCssReady = false;

  constructor(
    public override activatedRoute: ActivatedRoute,
    public override seoService: SeoService,
    public override errorAlert: ErrorAlertService,
    public override sanitizer: DomSanitizer,
    public override placeService: VisitorPlaceService,
    public override modalService: ModalService,
    public scriptService: ScriptService,
    public override dialogRef: MatDialogRef<FancyPlaceDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IPlaceDetailsModalData,
    private _placeService: VisitorPlaceService,
    public override _store: Store<IAppState>
  ) {
    super(
      activatedRoute,
      seoService,
      errorAlert,
      sanitizer,
      placeService,
      modalService,
      dialogRef,
      data,
      _store
    );

    // this._store.pipe(select(frontendSettingsSelector)).subscribe((settings) => {
    //   settings && (this.settings = settings);
    // });

    // handle browser back button click, close the event details view and reload the page
    window.addEventListener('popstate', this.hashChanged.bind(this));

    // // load the html editor css style so that the customized description style can be loaded
    const sunEditorCssUrl = `https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css`;

    // as this modal is ShadowDom, we need to inject the styles into it
    dialogRef.afterOpened().subscribe(() => {
      modalService.injectStyle(
        '.cdk-overlay-container ig-fancy-place-details-modal', [sunEditorCssUrl],
        () => {
          this.isCssReady = true;
        }
      );
    });

    // for testing AI assistant
    setTimeout(() => {
      if (['randy', 'test', 'test6'].includes(this.hubName)) {
        this.showMessager = true;
      }
    }, 1000);

    this.range = this.settings.nearbyPlacesDefaultRange;
  }

  get displayingNearbyEvents() {
    if (!this.filteredNearByEvents) {
      return this.nearByEvents;
    }
    return this.filteredNearByEvents;
  }

  get isAccommodation() {
    if (!this.settings) return false;
    return this.placeService.checkIsAccommodation(
      this.place,
      this.settings.placeCategories['Accommodations']
    );
  }

  get hasGoogleReviews() {
    return (
      !this.place.isManuallyCreated &&
      (this.place?.googleReviews?.filter((x) => x.text && +x.rating >= 3)
          .length ||
        this.place?.reviews?.filter((x) => x.text && +x.rating >= 3).length)
    );
  }

  isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  toReadableDate(value) {
    return value ? new Date(value).toLocaleDateString() : '';
  }

  getFrom(): string {
    switch (this.data.from) {
      case 'eventCalendar':
        return 'ALL EVENTS';
      case 'placeCalendar':
        return 'PLACES';
      case 'itinerary':
        return 'ITINERARY';
      default:
        return 'PLACES';
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public filterEventsByDate(command: {from: Date; to: Date}) {
    // this.filteredNearByEvents$ = this.nearByEvents$.pipe(
    //   switchMap((events) => this.filterEvents(events, command))
    // );
    this.filteredNearByEvents = this.filterEvents(this.nearByEvents, command);
  }

  filterEvents(events, command: {from: Date; to: Date}): VisitorEvent[] {
    const now = new Date();
    let matched: VisitorEvent[] = [];

    events?.forEach((item) => {
      if (!item.eventTimes || !item.eventTimes.length) {
        item.eventTimes = [
          {
            startTime: item.startTime,
            endTime: item.endTime,
          },
        ];
      }

      for (let time of item.eventTimes) {
        const startTime = time.startTime ? new Date(time.startTime) : null;
        const endTime = time.endTime ? new Date(time.endTime) : null;

        const isOngoing = endTime ? endTime >= now : true;
        const isUpcoming = startTime >= now;

        if (!isOngoing || !isUpcoming) {
          continue;
        }

        const isInRange =
          (!command.from && !command.to) ||
          (!endTime && startTime >= command.from && startTime <= command.to) ||
          (endTime && startTime <= command.to && endTime >= command.from) ||
          (endTime && startTime >= command.from && endTime <= command.to);

        if (isInRange) {
          matched.push(item);
          break;
        }
      }
    });

    return matched;
  }

  public goToEvent(event: VisitorEvent) {
    this.modalService.show({
      component: FancyEventDetailsModalComponent,
      panelClass: 'ig-modal-w-full',
      data: {
        currentEvent: event,
        allEvents: [],
        favCustomEvents: [],
        from: 'placeCalendar',
      } as IEventDetailsModalData,
    });
  }

  public setNearbyEventsRange(range: number) {
    this.nearbyEventsRange = range;
    this.filteredNearByEvents = null;
    this.nearByEvents = [];
    this.dateFilter?.clear(false);
    this.isAtEnd = false;
    this.isAtStart = true;
    this.matMenuTrigger?.closeMenu();
    this.loadNearbyEvents(range);
    if (this.isShowingNearbyEventsOnMap) {
      this.toggleShowNearbyEventsOnMap({checked: true, source: null});
    }
  }

  public checkIsAtStart() {
    if (this.nearbyEventsContainer) {
      setTimeout(() => {
        const {scrollLeft} = this.nearbyEventsContainer.nativeElement;
        this.isAtStart = scrollLeft === 0;
      }, 800);
    }
  }

  public checkIsAtEnd() {
    if (this.nearbyEventsContainer) {
      setTimeout(() => {
        const {scrollWidth, scrollLeft, clientWidth} =
          this.nearbyEventsContainer.nativeElement;

        this.isAtEnd = scrollWidth - (scrollLeft + clientWidth) <= 10;
      }, 800);
    }
  }

  public goRight() {
    if (this.nearbyEventsMode === 'timeline') {
      this.timeline?.goRight();
      return;
    }

    this.nearbyEventsContainer.nativeElement.scrollLeft +=
      this.getScrollWidth();

    this.checkIsAtStart();
    this.checkIsAtEnd();
  }

  public goLeft() {
    if (this.nearbyEventsMode === 'timeline') {
      this.timeline?.goLeft();
      return;
    }

    this.nearbyEventsContainer.nativeElement.scrollLeft -=
      this.getScrollWidth();

    this.checkIsAtStart();
    this.checkIsAtEnd();
  }

  getScrollWidth() {
    return (
      this.nearbyEventsContainer.nativeElement.getBoundingClientRect().width +
      16
    );
  }

  public toggleShowNearbyPlacesOnMap(status: MatCheckboxChange) {
    if (status.checked) {
      this.isShowingNearbyEventsOnMap = false;

      this.nearbyPlacesCom.nearByPlaces$.subscribe((places) => {
        const markers = places.map(
          (x) =>
            ({
              position: {lat: x.address.lat, lng: x.address.lng},
              options: {
                icon: 'https://iti-images.s3.amazonaws.com/imgs/place-48.png',
                zIndex: 2,
              },
              data: x,
            } as IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>)
        );

        this.keepPlaceMapMarker();
        this.mapMarkers = [...this.mapMarkers, ...markers];
        this.isShowingNearbyPlacesOnMap = true;
      });
    } else {
      this.keepPlaceMapMarker();
      this.isShowingNearbyPlacesOnMap = false;
    }
  }

  public markerClicked(
    marker: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>
  ) {
    if (marker.data._id === this.place._id) {
      this.googleMap.closeInfoWindow();
      return;
    }
    if (this.isShowingNearbyEventsOnMap) {
      this.googleMap.showEventInfoWindow();
    } else {
      this.googleMap.showPlaceInfoWindow();
    }
  }

  public changeNearbyEventsMode() {
    if (this.nearbyEventsMode === 'normal') {
      this.nearbyEventsMode = 'timeline';
    } else {
      this.nearbyEventsMode = 'normal';
    }
  }

  getDisplayCategory(place: VisitorPlace) {
    return this._placeService.getDisplayCategory(place);
  }

  /**
   * handle browser back button click, close the place details page
   * only close the details page is not enough, it will always have other loading issues for the script embedding solution
   * so force reload the page for now
   * */
  // @HostListener('window:popstate', ['$event'])
  public hashChanged($event: Event) {
    $event.preventDefault();
    let url = window.location.href.split('imgoing-place')[0];
    url = url.endsWith('?') ? url.substring(0, url.length - 1) : url;
    window.location.href = url;
    this.close();
  }
}
