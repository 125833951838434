import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Subject, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ImGoing';
  private onDestroy = new Subject<void>();

  constructor(updates: SwUpdate, private router: Router) {
    console.log('constructor of AppComponent');

    if (!updates.isEnabled) {
      return;
    }

    updates.versionUpdates
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event) => {
        updates.activateUpdate().then((isUpdated: boolean) => {
          isUpdated && location.reload();
        });
      });
  }

  async ngOnInit() {
    console.log('ngOnInit of AppComponent');
    this.hideSkeleton();

    const wrapper =
      document.getElementById('imgoingcalendar-wrapper') ||
      document.getElementById('imgoingcalendar-wrapper-wix');

    // for script solution, jump to the route defined in the wrapper element
    if (wrapper) {
      // adding "data-" to html element, as some CMS would remove other attributes
      const route =
        wrapper.attributes['route'] || wrapper.attributes['data-route'];
      console.log(route);
      if (!route) {
        alert(
          'Please specify the default URL for ImGoing in the wrapper element, e.g., <div id="imgoingcalendar-wrapper" route="/demo/events"></div>'
        );
        return;
      }

      // MacombIL has weird issue, the animation not working properly, here we manually add it
      if (route.value.includes('MacombIL')) {
        setTimeout(() => {
          this.intersect();
        }, 300);
      }

      const paramsObject = {};
      if (route.value.includes('?')) {
        // Create a URLSearchParams object with the query string parameters
        const searchParams = new URLSearchParams(route.value.split('?')[1]);

        for (const [key, value] of searchParams) {
          paramsObject[key] = value;
        }

        console.log('paramsObject', paramsObject);
      }

      this.router.navigate([route.value.split('?')[0]], {
        skipLocationChange: true,
        queryParams: paramsObject,
      });
    } else {
      // for non-script solution, if app.component is using shadow dom, we need to add the css file (the tailwind bundle) to the shadow dom
      // for script solution, the css file is loaded in the imgoingscript.js
      const root = document.getElementsByTagName('app-root')[0];
      if (root) {
        const shadowRoot = root.shadowRoot;
        const devServerDomain = 'devnext.imgoingcalendar.com'; //'74.207.233.92';

        // for running on localhost
        if (location.hostname === 'localhost') {
          const link = document.createElement('link');
          link.setAttribute('rel', 'stylesheet');
          link.setAttribute('href', 'styles.css');
          shadowRoot.appendChild(link);
        } else if (location.hostname === devServerDomain) {
          console.log(`-------Dev server-------`);
          this.loadStyleSheet(`https://${devServerDomain}/`, shadowRoot);
        } else {
          // if visit the site next.imgoingcalendar.com, we need to extract the css file (as its name is hashed) and add to shadow dom
          const domain = 'https://next.imgoingcalendar.com/';
          this.loadStyleSheet(domain, shadowRoot);

          // this.httpGetAsync(domain, (res) => {
          //   let style = res.match(/style(\S*).css/)[1];
          //   style = 'style' + style + '.css';
          //   link.setAttribute('href', style);

          //   // for place/event details page (shadow dom) to add the style
          //   console.log('--------------- styles -----------------', style);
          //   localStorage.setItem('STYLES_URL', domain + style);
          // });
        }
        // shadowRoot.appendChild(link);
        this.setHeight();
      }
    }

    // if (location.hostname !== 'localhost') {
    //   datadogRum.init({
    //     applicationId: 'de01fc6f-7b9e-404b-bf55-419ef15cdadf',  // Your Application ID
    //     clientToken: 'pub8c531496088a178f606029df0097ed9b',     // Your Client Token
    //     site: 'us5.datadoghq.com',                             // Replace with appropriate Datadog site if necessary
    //     service: 'imgoing--web',                               // Set the service name (customize as needed)
    //     env: 'dev',                                     // Set environment, e.g., 'production' or 'staging'
    //     sessionSampleRate: 100,                                       // Adjust session sampling rate (100% here)
    //     sessionReplaySampleRate: 20,                           // Adjust session replay rate as needed
    //     trackUserInteractions: true,                           // Enables interaction tracking
    //     trackResources: true,                                  // Enables resource tracking
    //     trackLongTasks: true,                                  // Enables long task tracking
    //     defaultPrivacyLevel: 'mask-user-input'                 // Sets the privacy level for user inputs
    //   });
    // }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  private hideSkeleton() {
    const wholeSpinner = document.getElementById('skeleton-mask');
    if (wholeSpinner) {
      wholeSpinner.style.display = 'none';
    }
  }

  private loadStyleSheet(domain: string, shadowRoot: ShadowRoot): void {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');

    this.httpGetAsync(domain, (res) => {
      let style = res.match(/style(\S*).css/)[1];
      style = 'style' + style + '.css';
      link.setAttribute('href', style);

      // for place/event details page (shadow dom) to add the style
      console.log('--------------- styles -----------------', style);
      localStorage.setItem('STYLES_URL', domain + style);

      shadowRoot.appendChild(link);
    });
  }

  private httpGetAsync(theUrl, callback) {
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        callback(xmlHttp.responseText);
      }
    };
    xmlHttp.open('GET', theUrl, true); // true for asynchronous
    xmlHttp.send(null);
  }

  private intersect() {
    // root is the browser viewport / screen
    var observer = new IntersectionObserver(
      function (entries) {
        // since there is a single target to be observed, there will be only one entry
        if (entries[0]['isIntersecting'] === true) {
          // if (entries[0]['intersectionRatio'] > 0.5)
          //   console.log('More than 50% of target is visible in the screen');
          entries[0].target.classList.add('fl-animated');
        }
      },
      {threshold: [0, 0.5, 1]}
    );

    const eles = document.querySelectorAll('.fl-animation');
    const right = document.querySelector('#bottom-right-card');
    // const eles = document.querySelectorAll('.div');
    console.log('eles.length ----', eles.length);
    eles.forEach((ele) => {
      observer.observe(ele as Element);
    });
    observer.observe(right as Element);
  }

  private setHeight() {
    document.body.style.height = '100%';
    document.querySelector('html').style.height = '100%';
  }
}
