<div (click)="openTripMenu()" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="actionMenu" igClickStopPropagation
  (menuClosed)="onClose()"
  class="bg-gray-50 border-[#ccc] border rounded-sm flex gap-3 items-center justify-center !p-2 w-full cursor-pointer"
  [class.ig-add-to-trip-tp-half]="mode==='half'" [class.ig-add-to-trip-tp-half-in]="mode==='half' && isInAnyTrip">
  <ng-container *ngIf="mode!=='half'">
    <svg *ngIf="!isInAnyTrip" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0,0,256,256">
      <g fill="#47b028" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
        stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
        font-size="none" text-anchor="none" style="mix-blend-mode: normal">
        <g transform="scale(10.66667,10.66667)">
          <path
            d="M2,6c0,-2.209 1.791,-4 4,-4c2.209,0 4,1.791 4,4c0,1.782 -2.032,4.326 -3.2,5.64c-0.426,0.479 -1.176,0.479 -1.601,0c-1.167,-1.313 -3.199,-3.858 -3.199,-5.64z"
            opacity="0.35"></path>
          <circle cx="6" cy="6" r="1.5"></circle>
          <path
            d="M14,16c0,-2.209 1.791,-4 4,-4c2.209,0 4,1.791 4,4c0,1.782 -2.032,4.326 -3.2,5.64c-0.426,0.479 -1.176,0.479 -1.601,0c-1.167,-1.313 -3.199,-3.858 -3.199,-5.64z"
            opacity="0.35"></path>
          <circle cx="18" cy="16" r="1.5"></circle>
          <circle cx="6" cy="15" r="1"></circle>
          <circle cx="9" cy="19" r="1"></circle>
          <circle cx="14" cy="21" r="1"></circle>
        </g>
      </g>
    </svg>
    <svg *ngIf="isInAnyTrip" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px" height="24px" viewBox="0,0,256,256">
      <g fill="#d87849" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
        stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
        font-size="none" text-anchor="none" style="mix-blend-mode: normal">
        <g transform="scale(8.53333,8.53333)">
          <path
            d="M19.22266,3c-1.227,0 -2.22266,0.99566 -2.22266,2.22266c0,1.791 2.59747,4.02903 3.60547,4.83203c0.233,0.185 0.55606,0.185 0.78906,0c1.009,-0.803 3.60547,-3.04103 3.60547,-4.83203c0,-1.227 -0.99566,-2.22266 -2.22266,-2.22266c-0.731,0 -1.37234,0.35639 -1.77734,0.90039c-0.405,-0.544 -1.04734,-0.90039 -1.77734,-0.90039zM9,12c-3.314,0 -6,2.686 -6,6c0,3.946 3.85216,5.75773 4.28516,6.30273c0.492,0.621 0.72695,1.88275 0.75195,1.96875c0.118,0.421 0.50489,0.72852 0.96289,0.72852c0.458,0 0.84389,-0.30752 0.96289,-0.72852c0.024,-0.086 0.25895,-1.34775 0.75195,-1.96875c0.432,-0.545 4.28516,-2.35673 4.28516,-6.30273c0,-3.314 -2.686,-6 -6,-6zM23,12c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM25,15c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM9,16c1.105,0 2,0.895 2,2c0,1.105 -0.895,2 -2,2c-1.105,0 -2,-0.895 -2,-2c0,-1.105 0.895,-2 2,-2zM26,19c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM24,22c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM21,24c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM13,25c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1zM17,25c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1z">
          </path>
        </g>
      </g>
    </svg>
  </ng-container>

  <a class="font-semibold text-sm" [class.!text-[11px]]="mode==='half'">{{
    isInAnyTrip ? inTripText : addToTripText
    }}</a>
</div>


<mat-menu [yPosition]="'above'" #actionMenu="matMenu" (closed)="isAddingNewTrip=false" class="ig-trip-menu">
  <div class="flex h-full w-full" igClickStopPropagation>
    <div
      class="ig-trip-img w-[36%] transition mobile:w-full mobile:-z-10 mobile:absolute mobile:h-full mobile:opacity-70">
    </div>

    <div class="w-[64%] px-7 pb-7 pt-4 mobile:w-full" #logindiv>
      <div *ngIf="isMenuOpened && !isUserLoggedIn" class="mt-11">
        <p class="text-center !text-xl font-semibold text-gray-700 mt-6">Login to Create Your Trips</p>
        <p class="text-center text-xs text-gray-600 italic mt-1 !mb-6">Create your own trips and explore our Trip
          Planner
        </p>
        <div class="h-[100px] w-full flex items-center justify-center">
          <ig-social-login #socialLogin (onLoginStatusChanged)="loginStatusChanged($event)"></ig-social-login>
        </div>
      </div>

      <div *ngIf="isUserLoggedIn && isLoadingTrips" class="h-full w-full flex justify-around items-center">
        <ig-spinner></ig-spinner>
      </div>

      <div *ngIf="isUserLoggedIn && !isLoadingTrips && !trips?.length">
        <div class="flex justify-around items-center mt-8 h-11">
          <p class="text-center !text-xl font-semibold text-gray-700">Create Your First Trip</p>
          <ig-social-login class="block w-12 h-11"></ig-social-login>
        </div>

        <p class="text-center text-xs text-gray-600 italic mt-1 !mb-6">Create your own trips and explore our Trip
          Planner
        </p>

        <ig-field #tripTitle label="Enter your trip title" cls="w-full" (onInputFocus)="tripTitleInputFocus($event)"
          (onEnterPress)="saveNewTrip()" [control]="tripFormGroup.get('tripTitle')"></ig-field>

        <ig-button cls="w-full" (trigger)="saveNewTrip()">
          CREATE TRIP
        </ig-button>
      </div>

      <div *ngIf="isUserLoggedIn && !isLoadingTrips && trips?.length"
        class="overflow-y-auto overflow-x-hidden h-full p-1 items-center justify-center flex-col"
        [class.flex]="isAddingNewTrip">
        <div class="flex justify-between items-center mb-1.5 w-full gap-2">
          <a *ngIf="!isAddingNewTrip&&!isOverLimit" class="block ig-trip-create-mobile"
            (click)="isAddingNewTrip=true;tripFormGroup.get('tripTitle')?.markAsUntouched()">
            Create a New Trip</a>
          <span *ngIf="isAddingNewTrip" class="text-gray-600 font-semibold">Create a New Trip</span>
          <ig-social-login [class.hide]="isAddingNewTrip"></ig-social-login>
        </div>

        <div class="flex flex-col mt-2 w-full ig-trip-new" *ngIf="isAddingNewTrip">
          <ig-field #tripTitle label="Enter your trip title" class="w-full" cls="w-full"
            (onInputFocus)="tripTitleInputFocus($event)" (onEnterPress)="saveNewTrip()"
            [control]="tripFormGroup.get('tripTitle')"></ig-field>

          <ig-button class="-translate-y-2" cls="w-full" (trigger)="saveNewTrip()">
            CREATE
          </ig-button>
          <span class="text-gray-500 underline cursor-pointer text-center text-sm"
            (click)="isAddingNewTrip=false">Cancel</span>
        </div>

        <div *ngIf="!isAddingNewTrip">
          <div #buttons mat-menu-item *ngFor="let trip of trips;let i = index" [attr.data-index]="i"
            class="!mb-3 !bg-gray-100 relative rounded-lg ig-trip-menu-btn hover:shadow-md transition-all"
            [class.!px-0]="trip.isShowingDetails"
            (click)="!trip.isShowingDetails&&!trip.isSuccess&&addOrRemoveFromTrip($event, i)">

            <mat-icon *ngIf="!trip.isShowingDetails&&!trip.isSuccess"
              class="absolute -right-4 top-1 text-center !text-gray-500 z-10" igClickStopPropagation
              (click)="trip.isShowingDetails=true" matTooltip="view details">chevron_left
            </mat-icon>
            <mat-icon *ngIf="trip.isShowingDetails" class="absolute -right-4 top-1 text-center !text-white z-10"
              igClickStopPropagation (click)="trip.isShowingDetails=false">expand_more
            </mat-icon>
            <mat-icon *ngIf="trip.isShowingDetails" class="absolute left-2 top-1 text-center !text-red-500 z-10"
              igClickStopPropagation matTooltip="delete trip" #deleteMenuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="deleteMenu">delete
            </mat-icon>
            <mat-menu [yPosition]="'above'" #deleteMenu="matMenu">
              <div class="flex items-center gap-2">
                <mat-icon class="!w-1/3 text-center">delete</mat-icon>
                <div class="pr-4">
                  <span class="text-sm">Are you sure you want to delete the trip?</span>
                  <button class="w-full bg-red-500 text-white rounded-xl text-center my-1" (click)="deleteTrip(i)"
                    igClickStopPropagation>Delete
                  </button>
                </div>
              </div>
            </mat-menu>

            <div *ngIf="!trip.isShowingDetails&&!trip.isSuccess" class="ellipsis pr-4">
              <span trip class="relative top-1.5 leading-7">"{{ trip.title }}"</span>
              <span *ngIf="!isInTrip(i)" class="absolute top-1 left-2 text-xs text-green-600">Add to</span>
              <span *ngIf="isInTrip(i)" class="absolute top-1 left-2 text-xs text-orange-600">Remove from</span>
            </div>

            <div *ngIf="trip.isSuccess" class="flex gap-3 items-center" [class.ig-trip-removed]="!isInTrip(i)">
              <ig-success-checkmark [width]="28"></ig-success-checkmark>
              <div *ngIf="isInTrip(i)" class="text-sm !text-[#7ac142] max-lines-2 pr-3">Added to "{{ trip.title }}"
              </div>
              <div *ngIf="!isInTrip(i)" class="text-sm !text-[#ff8834] max-lines-2 pr-3">Removed from "{{ trip.title }}"
              </div>
            </div>

            <div *ngIf="trip.isShowingDetails" class="h-[180px] relative">
              <ig-image [src]="trip.attractions[0]?.item?.cover?.source"></ig-image>

              <div class="w-full h-full top-0 left-0 absolute"
                style="background:linear-gradient(to bottom, transparent, black)"></div>

              <div class="absolute bottom-3 w-[90%] left-[5%] z-20">
                <div class="text-base text-white font-semibold max-lines-2 mb-1">{{ tripTitleWithStops(trip) }}</div>

                <ul *ngIf="trip.attractions.length<=3" class="pl-2 list-inside list-[circle]">
                  <li *ngFor="let item of trip.attractions" class="text-white text-xs ellipsis">{{ item.item.name }}
                  </li>
                </ul>
                <ul *ngIf="trip.attractions.length>3" class="pl-3 list-inside list-[circle]">
                  <li class="text-white text-xs ellipsis">{{ trip.attractions[0].item.name }}</li>
                  <li class="text-white text-xs ellipsis">...</li>
                  <li class="text-white text-xs ellipsis">{{ trip.attractions[trip.attractions.length - 1].item.name }}
                  </li>
                </ul>

                <button *ngIf="!isInTrip(i)"
                  class="px-4 py-1 mt-1 w-full rounded-2xl text-white bg-[#41abf3] transition-all hover:bg-[#11a0ff]"
                  (click)="addOrRemoveFromTrip($event, i)">
                  Add to this trip
                </button>
                <button *ngIf="isInTrip(i)"
                  class="px-4 py-1 mt-1 w-full rounded-2xl text-white bg-[#ff8834] transition-all hover:bg-[#fd720f]"
                  (click)="addOrRemoveFromTrip($event, i)">
                  Remove from this trip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-menu>