<div class="ig-social-login">
  <div *ngIf="mode === 'popup'">
    <ng-content select="[loginBtn]"></ng-content>

    <div *ngIf="!profilePicture"
         class="px-3 py-2 !text-[13px] bg-blue-400 text-white rounded-md cursor-pointer"
         #loginMenuTrigger="matMenuTrigger" (menuOpened)="initGoogleLogin()"
         [matMenuTriggerFor]="loginMenu">Login
    </div>

    <ig-image *ngIf="profilePicture" [src]="profilePicture" [matMenuTriggerFor]="actionMenu"
              cls="w-10 h-10 rounded-full" alt="profile img"></ig-image>

    <mat-menu #loginMenu="matMenu"
              class="ig-width-fit !rounded-md px-6 py-2">
      <div igClickStopPropagation [class.flex]="mode==='horizontal'" class="items-center gap-2">
        <ng-container *ngTemplateOutlet="myReusableTemplate;"></ng-container>
      </div>
    </mat-menu>
  </div>

  <img #profileImg *ngIf="profilePicture && mode !== 'popup'" [matMenuTriggerFor]="actionMenu" [src]="profilePicture"
       alt="user profile picture" class="w-11 rounded-full cursor-pointer aspect-square">


  <div [class.flex]="mode==='horizontal'" [class.ig-hide]="mode === 'popup'" class="items-center gap-2">
    <ig-google-login #google [class.ig-hide]="profilePicture" class="block">
      <div id="google-signin-btn" class="google-signin-btn"></div>
    </ig-google-login>

    <div [class.ig-hide]="profilePicture">
      <ig-facebook-login #facebook *ngIf="googleReady$ | async; else loading"
                         class="block mt-3 w-[294px] smallMobile:w-[260px]" [class.!mt-0]="mode==='horizontal'">
      </ig-facebook-login>
    </div>
  </div>

  <ng-template #myReusableTemplate let-data>
    <ig-google-login #google [class.ig-hide]="profilePicture" class="block">
      <div id="google-signin-btn" class="google-signin-btn"></div>
    </ig-google-login>

    <div [class.ig-hide]="profilePicture">
      <ig-facebook-login #facebook *ngIf="googleReady$ | async; else loading"
                         class="block mt-3 w-[294px] smallMobile:w-[260px]" [class.!mt-0]="mode==='horizontal'">
      </ig-facebook-login>
    </div>
  </ng-template>

  <ng-template #loading>
    <div class="flex justify-center">
      <ig-spinner></ig-spinner>
    </div>
  </ng-template>

  <mat-menu #actionMenu="matMenu">
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</div>
