import { CreateEventComponent } from './modules/event-calendar/components/create.event/create.event.component';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { routeConfigs } from './common/constants/route.config';
import {
  VisitorEventMapComponent
} from './modules/event-calendar/components/visitor.event.map/visitor.event.map.component';
import {
  VisitorCalendarComponent
} from './modules/shared/components/visitor/visitor.calendar/visitor.calendar.component';
import { VisitorComponent } from './modules/shared/components/visitor/visitor.component';
import {
  AiBuddyPlaceListingComponent
} from './modules/place-calendar/components/ai.buddy.place.listing/ai.buddy.place.listing.component';

const routes: Routes = [
  /** for non lazy loading */
  // {
  //   path: routeConfigs.visitor.path,
  //   component: VisitorComponent,
  //   children: [
  //     {
  //       path: routeConfigs.visitor.eventCalendar.path,
  //       component: VisitorCalendarComponent,
  //     },
  //     {
  //       path: routeConfigs.visitor.eventCalendar.eventMap.path,
  //       component: VisitorEventMapComponent,
  //     },
  //     {
  //       path: routeConfigs.visitor.eventCalendar.createEvent.path,
  //       component: CreateEventComponent,
  //     },
  //     {
  //       path: routeConfigs.visitor.eventDetailsModal.path,
  //       component: VisitorCalendarComponent,
  //     },

  //     {
  //       path: routeConfigs.visitor.placeCalendar.path,
  //       component: VisitorCalendarComponent,
  //     },
  //   ],
  // },

  /** for lazying loading */
  {
    path: routeConfigs.visitor.path,
    component: VisitorComponent,
    children: [
      {
        path: routeConfigs.visitor.eventCalendar.path,
        loadChildren: () => {
          try {
            return import(
              './modules/event-calendar/event-calendar.module'
              ).then((m) => m.EventCalendarModule);
          } catch (error) {
            return null;
          }
        },
      },
      {
        path: routeConfigs.visitor.placeCalendar.path,
        loadChildren: () =>
          import('./modules/place-calendar/place-calendar.module').then(
            (m) => m.PlaceCalendarModule
          ),
      },
      {
        path: routeConfigs.visitor.ai.path,
        children: [
          {
            path: routeConfigs.visitor.ai.travelBuddy.path,
            component: AiBuddyPlaceListingComponent,
          },
        ],
      },

      /** for compatible with V2 routes */
      {
        path: routeConfigs.visitor.favCustomPlace.path,
        redirectTo:
          routeConfigs.visitor.placeCalendar.path +
          '/' +
          routeConfigs.visitor.placeCalendar.favCustomPlace.path,
      },
      {
        path: routeConfigs.visitor.defaultPlace.path,
        redirectTo:
          routeConfigs.visitor.placeCalendar.path +
          '/' +
          routeConfigs.visitor.placeCalendar.category.path,
      },
      {
        path: routeConfigs.visitor.customDefaultPlace.path,
        redirectTo:
          routeConfigs.visitor.placeCalendar.path +
          '/' +
          routeConfigs.visitor.placeCalendar.customDefaultPlace.path,
      }
    ],
  },
  {
    path: routeConfigs.tripPlanner.path,
    loadChildren: () =>
      import('./modules/trip-planner/trip-planner.module').then(
        (m) => m.TripPlannerModule
      ),
  },
];

/** so that child component can get parent component's params */
export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'disabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
